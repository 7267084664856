@import "./styles/common.css";
.section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.connect-button.MuiButton-contained {
  background: #ed8624;
  margin-bottom: -20px;
}
.connect-button.MuiButton-contained:hover {
  background: #fbb552;
}

.relics-button.MuiButton-contained {
  background-color: #472315;
  color: white;
  font-weight: 800;
}
.relics-button.MuiButton-contained:hover {
  background: #8b1c1b;
}

.contract-button.MuiButton-contained {
  background-color: #01b9e2;
  color: white;
}
.contract-button.MuiButton-contained:hover {
  background: #025c6f;
}