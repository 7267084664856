@import "./typography.css";
body {
  font-family: didact-gothic;
  position: relative;
}
ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}
#alchemy-cert {
  position: absolute;
  bottom: 2vh;
  right: 1vw;
}
#alchemy-cert #badge-button {
  height: 35px;
}