@charset "UTF-8";
@font-face {
  font-family: "Helvetica Neue LT Std";
  src: url('../../fonts/HelveticaNeueLTStd-Bd.eot');
  src: url('../../fonts/HelveticaNeueLTStd-Bd.eot?#iefix') format('embedded-opentype'), url('../../fonts/HelveticaNeueLTStd-Bd.woff2') format('woff2'), url('../../fonts/HelveticaNeueLTStd-Bd.woff') format('woff'), url('../../fonts/HelveticaNeueLTStd-Bd.svg#HelveticaNeueLTStd-Bd') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Helvetica Neue LT Std";
  src: url('../../fonts/HelveticaNeueLTStd-Roman.eot');
  src: url('../../fonts/HelveticaNeueLTStd-Roman.eot?#iefix') format('embedded-opentype'), url('../../fonts/HelveticaNeueLTStd-Roman.woff2') format('woff2'), url('../../fonts/HelveticaNeueLTStd-Roman.woff') format('woff'), url('../../fonts/HelveticaNeueLTStd-Roman.svg#HelveticaNeueLTStd-Roman') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "London Font";
  src: url('../../fonts/LondonFont.eot');
  src: url('../../fonts/LondonFont.eot?#iefix') format('embedded-opentype'), url('../../fonts/LondonFont.woff2') format('woff2'), url('../../fonts/LondonFont.woff') format('woff'), url('../../fonts/LondonFont.svg#LondonFont') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* W3.CSS 4.15 December 2020 by Jan Egil and Borge Refsnes */
html{box-sizing:border-box}*,*:before,*:after{box-sizing:inherit}
/* Extract from normalize.css by Nicolas Gallagher and Jonathan Neal git.io/normalize */
html{-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%}body{margin:0}
article,aside,details,figcaption,figure,footer,header,main,menu,nav,section{display:block}summary{display:list-item}
audio,canvas,progress,video{display:inline-block}progress{vertical-align:baseline}
audio:not([controls]){display:none;height:0}[hidden],template{display:none}
a{background-color:transparent}a:active,a:hover{outline-width:0}
abbr[title]{border-bottom:none;text-decoration:underline;text-decoration:underline dotted}
b,strong{font-weight:bolder}dfn{font-style:italic}mark{background:#ff0;color:#000}
small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}
sub{bottom:-0.25em}sup{top:-0.5em}figure{margin:1em 40px}img{border-style:none}
code,kbd,pre,samp{font-family:monospace,monospace;font-size:1em}hr{box-sizing:content-box;height:0;overflow:visible}
button,input,select,textarea,optgroup{font:inherit;margin:0}optgroup{font-weight:bold}
button,input{overflow:visible}button,select{text-transform:none}
button,[type=button],[type=reset],[type=submit]{-webkit-appearance:button}
button::-moz-focus-inner,[type=button]::-moz-focus-inner,[type=reset]::-moz-focus-inner,[type=submit]::-moz-focus-inner{border-style:none;padding:0}
button:-moz-focusring,[type=button]:-moz-focusring,[type=reset]:-moz-focusring,[type=submit]:-moz-focusring{outline:1px dotted ButtonText}
fieldset{border:1px solid #c0c0c0;margin:0 2px;padding:.35em .625em .75em}
legend{color:inherit;display:table;max-width:100%;padding:0;white-space:normal}textarea{overflow:auto}
[type=checkbox],[type=radio]{padding:0}
[type=number]::-webkit-inner-spin-button,[type=number]::-webkit-outer-spin-button{height:auto}
[type=search]{-webkit-appearance:textfield;outline-offset:-2px}
[type=search]::-webkit-search-decoration{-webkit-appearance:none}
::-webkit-file-upload-button{-webkit-appearance:button;font:inherit}
/********************** End extract *********************/

html, body, .w3-container, .box00 {
  margin: 0 !important;
  padding: 0 !important;
  width: 100vw;
  position: absolute !important;
  min-height: 100vh !important;
  top: 0 !important;
  left: 0 !important;
  overflow-x: hidden;
  scroll-behavior: smooth;
}
/********************** UNIVERSAL *********************/
html, body {
  font-size: 20px;
  line-height: 20px;
}
iframe {
  -webkit-overflow-scrolling: touch;
}
/*hiding all scrollbars*/
html, body, .w3-container, .box00, iframe, .etrack, .track, .noticemarquee, .mySidebar, .mySidebar2 {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
/*hide scrollbar chrome safari opera*/
html::-webkit-scrollbar, body::-webkit-scrollbar, .w3-container::-webkit-scrollbar, .box00::-webkit-scrollbar, iframe::-webkit-scrollbar, .topnav::-webkit-scrollbar, .etrack::-webkit-scrollbar, .track::-webkit-scrollbar, .noticemarquee::-webkit-scrollbar, .mySidebar::-webkit-scrollbar, .mySidebar2::-webkit-scrollbar {
  display: none
}
/****page border***/
.stroketop, .strokeleft, .strokeright, .strokebottom {
  position: absolute;
  display: block;
  z-index: 6010;
}
.stroketop {
  width: 100vw;
  height: 3px;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
}
.strokeleft {
  height: 100vh;
  width: 3px;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
}
.strokeright {
  height: 100vh;
  width: 3px;
  background-color: white;
  position: fixed;
  bottom: 0;
  right: 0;
}
.strokebottom {
  width: 100vw;
  height: 3px;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
}
/****top nav*****/
.topnav {
  width: 4rem;
  height: 13rem;
  position: fixed;
  z-index: 6008 !important;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0rem 1rem;
  border-radius: 100rem;
}
.topnav .h7 {
  width: 2rem;
  height: 2rem;
  border-radius: 4rem;
  float: left;
  display: block;
  padding: 0.65rem 0 0 0;
  text-align: center;
  font-family: 'Helvetica Neue LT Std', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';
  font-weight: bold;
  font-style: normal;
  font-size: 1rem;
  line-height: 1rem;
  transition: all 0.2s ease;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
}
.topnav .btn1 .h7, .topnav .btn2 .h7, .topnav .btn3 .h7, .topnav .btn4 .h7 {
  margin: 1rem auto 0 auto;
}
.topnav .btn4 .h7 {
  background-color: #FFD400;
  color: #282525;
}
.topnav .btn1 .h7 {
  background-color: #25B180;
  color: white;
}
.topnav .btn2 .h7 {
  background-color: #BC00FF;
  color: white;
}
.topnav .btn3 .h7 {
  background-color: #ff0049;
  color: white;
}
.topnav .btn1 .h7:hover, .topnav .btn1 .h7:focus, .topnav .btn2 .h7:hover, .topnav .btn2 .h7:focus, .topnav .btn3 .h7:hover, .topnav .btn3 .h7:focus, .topnav .btn4 .h7:hover, .topnav .btn4 .h7:focus, .topnav .btn5 .h7:hover, .topnav .btn5 .h7:focus {
  background-color: #D79417;
  color: white !important;
}
/****marquee and esc image*****/
.noticemarquee {
  width: 100vw;
  height: 1.5rem;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color: #D79417;
  color: white;
  position: fixed;
  bottom: 0;
  z-index: 6007;
  border-top: 3px solid white;
  font-family: 'London Font', Consolas, "Courier New", "monospace";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  font-size: 1.2rem;
  letter-spacing: 0.1rem;
  line-height: 1.25em;
}
.noticemarquee:hover, .noticemarquee:focus {
  background-color: #F5D59B;
  color: #D79417;
}
.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
}
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
.etrack {
  animation: marquee 200s linear infinite;
}
/****whitelist form going to be shipping form****/
#mySidebar {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(180deg, #F5D59B, rgba(237, 183, 85, 0.95));
  margin: 0;
  padding: 0 0 0 0;
  z-index: 6009 !important;
  border-bottom: 3px solid white;
}
#mySidebar iframe {
  width: 100%;
  height: 100%;
  padding: 0 0 0 0;
  margin: 4rem auto 0 auto;
  position: fixed;
  display: block;
  z-index: 200;
}
.whitelist-close-btn {
  position: fixed;
  top: -1rem;
  right: -1rem;
  height: 8rem;
  width: 8rem;
  border-radius: 10rem;
  border: 1px dotted black;
  background-color: #D79417;
  background-image: url("../../assets/ico-x.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 3rem;
  transition: all 0.1s ease;
  z-index: 201;
}
.whitelist-close:hover .whitelist-close-btn, .whitelist-close:focus .whitelist-close-btn {
  top: 0.5rem;
  right: 0.5rem;
  background-color: black;
  border: 1px dotted yellow !important;
  height: 10rem;
  width: 10rem;
}
/******HOW TO*****/
#mySidebar2 {
  height: 100%;
  width: 100%;
  background-color: #ffd400;
  margin: 0;
  padding: 2rem 2rem 5rem 2rem;
  z-index: 6009 !important;
  border-bottom: 3px solid white;
  display: none;
}
.sidebar2-close-btn {
  position: fixed;
  top: -1rem;
  right: -1rem;
  height: 8rem;
  width: 8rem;
  border-radius: 10rem;
  border: 1px dotted black;
  background-color: #005da8;
  background-image: url("../../assets/ico-x.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 3rem;
  transition: all 0.1s ease;
  z-index: 201;
}
.sidebar2-close-btn:hover, .sidebar2-close-btn:focus {
  top: 0.5rem;
  right: 0.5rem;
  background-color: black;
  height: 10rem;
  width: 10rem;
}
#mySidebar2 hr {
  border-top: 3px solid #005da8;
  border-bottom: 0;
  max-width: 36rem;
  margin: 0;
}
#mySidebar2 h1 {
  font-family: 'London Font', Consolas, "Courier New", "monospace";
  font-size: 2rem;
  line-height: 1.2em;
  font-weight: normal;
  font-style: normal;
  color: #005da8;
  max-width: 30rem;
  text-decoration: underline;
  margin: 0;
}
#mySidebar2 h2 {
  font-family: 'London Font', Consolas, "Courier New", "monospace";
  font-weight: normal;
  font-style: normal;
  color: #005da8;
  font-size: 1.5rem;
  line-height: 1.3em;
  max-width: 35rem;
}
#mySidebar2 h3, #mySidebar2 p {
  font-family: 'Helvetica Neue LT Std', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';
  font-style: normal;
}
#mySidebar2 h3 {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.3em;
  margin: 1.5rem 0 1rem 0;
  padding: 0;
}
#mySidebar2 p {
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 2em;
  max-width: 18rem;
  margin: 0 0 0.5rem 2rem;
  padding: 0;
}
#mySidebar2 .num {
  font-weight: 700 !important;
  margin-left: -1rem;
}
#mySidebar2 .step3 {
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #25b180;
  border-radius: 10rem;
  padding: 0.5rem 1rem 0.2rem 1rem;
  line-height: 1em;
  display: block;
  margin: -1.7rem 0 0.7rem 0;
  width: fit-content;
  clear: both;
  text-align: center;
  width: 12rem;
}
#mySidebar2 .step4 {
  display: block;
  width: 100%;
  max-width: 15rem;
  height: auto;
  content: url("../../assets/slider-example.jpg");
  margin-bottom: 2rem;
}
#mySidebar2 .step6 {
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #bc00ff;
  border-radius: 10rem;
  padding: 0.5rem 1rem 0.2rem 1rem;
  line-height: 1em;
  display: block;
  margin: 1rem 0 0 0;
  width: fit-content;
  clear: both;
  text-align: center;
  width: 12rem;
}
#mySidebar2 .step7 {
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #bc00ff;
  border-radius: 10rem;
  padding: 0.5rem 1rem 0.2rem 1rem;
  line-height: 1em;
  display: block;
  margin: -2.7rem 0 2rem 0;
  clear: both;
  text-align: center;
  width: 12rem;
}
/****landing*****/
.box1 {
  background-image: url("../../assets/box1_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
  border-bottom: 2px solid white;
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  z-index: 0;
  margin: 0 auto;
}
.box1b {
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8));
  height: 100vh;
  border-bottom: 2px solid white;
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  z-index: 1;
}
.box1c {
  width: 26.5rem;
  height: 20rem;
  max-height:30vh;
  position: absolute;
  top: 2rem;
  display: block;
  z-index: 3;
  background-image: url("../../assets/logo.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left center;
  left: 2rem;
}
.box1d {
  height: 100vh;
  z-index: 4;
  position: relative;
}
.firstbuy {
  height: auto;
  bottom: 0rem;
  position: absolute;
  left: 1rem;
}
/****about*****/
.box2 {
  background-color: #005DA8;
  padding: 8rem 2rem;
  border-bottom: 2px solid white;
}
.box2 h1 {
  font-family: 'London Font', Consolas, "Courier New", "monospace";
  font-size: 4rem;
  line-height: 1.1em;
  letter-spacing: 0rem;
  color: yellow;
  text-align: center;
  font-weight: normal;
  font-style: normal;
  margin: 0;
  padding: 0;
}
.box2 p {
  font-family: 'Helvetica Neue LT Std', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  margin: 5rem auto 0 auto;
  padding: 0 !important;
  color: white;
  font-size: 0.85rem;
  line-height: 1.5em;
  width: 100%;
  max-width: 24rem;
}
.box2 p .hightlight {
  color: #000000 !important;
}
/****details*****/
.box4 {
  background-image: linear-gradient(180deg, #f5d59b, #e7ddd1);
  min-height: 100vh;
  padding: 0;
}
.box4 h1 {
  font-family: 'London Font', Consolas, "Courier New", "monospace";
  font-size: 4rem;
  line-height: 1.1em;
  letter-spacing: 0rem;
  color: black;
  text-align: center;
  font-weight: normal;
  font-style: normal;
  margin: 8rem auto 2rem auto;
  padding: 0 2rem;
  width: 100%;
  max-width: 32rem;
}
.box4 h2 {
  color: #005da8;
  font-family: 'Helvetica Neue LT Std', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';
  font-weight: 700;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.5em;
  width: 100%;
  text-align: center;
  margin: 0;
  padding: 0 2rem;
}
.box4 p {
  color: black;
  font-family: 'Helvetica Neue LT Std', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';
  font-weight: 400;
  font-style: normal;
  font-size: 0.8rem;
  line-height: 1.5em;
  width: 100%;
  max-width: 23rem !important;
  margin: 0 auto 1.5rem auto;
  padding: 0 2rem;
  text-align: center;
}
.box4span {
  color: black;
  font-weight: 700 !important;
}
.guideimage {
  background-image: url("../../assets/guide_mockup.png");
  width: 100%;
  max-width: 40rem;
  height: 20rem;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 auto !important;
  clear: both;
  display: block;
}
.box4 .arrow {
  width: 7rem;
  height: auto;
  content: url("../../assets/box4_arrow.svg");
  margin: 6rem 0 -1.5rem 60vw;
  display: block;
}
.tier1 {
  background-color: #d79417;
  padding: 0 0 2rem 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  border-top: 1px dotted #282525;
  width: 50%;
  float: left;
}
.tier2 {
  background-color: #282525;
  padding: 0 0 2rem 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  border-top: 1px dotted #282525;
  width: 50%;
  float: right;
}
.tier3 {
  background-color: #005da8;
  padding: 0 0 2rem 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  border-top: 1px dotted #282525;
  border-top: 1px dotted #282525;
  width: 50%;
  float: left;
}
.tier4 {
  background-color: #f5d59b;
  padding: 0 0 2rem 0;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  border-top: 1px dotted #282525;
  width: 50%;
  float: right;
}
.box4bcard {
  width: 100%;
  margin: 2rem auto 1rem auto;
  padding: 0 1rem;
}
.tier1 .box4bcard {
  content: url("../../assets/cards/1.png");
}
.tier2 .box4bcard {
  content: url("../../assets/cards/2.png");
}
.tier3 .box4bcard {
  content: url("../../assets/cards/3.png");
}
.tier4 .box4bcard {
  content: url("../../assets/cards/4.png");
}
.tier1 .box4bcard:hover, .tier1 .box4bcard:focus {
  content: url("../../assets/cards/1b.png");
}
.tier2 .box4bcard:hover, .tier2 .box4bcard:focus {
  content: url("../../assets/cards/2b.png");
}
.tier3 .box4bcard:hover, .tier3 .box4bcard:focus {
  content: url("../../assets/cards/3b.png");
}
.tier4 .box4bcard:hover, .tier4 .box4bcard:focus {
  content: url("../../assets/cards/4b.png");
}
.tier1 p {
  color: #e7ddd1 !important
}
.tier2 p {
  color: #e28252 !important
}
.tier3 p {
  color: #e7ddd1 !important
}
.tier4 p {
  color: #005da8 !important
}
.box4b {
  background-color: yellow;
}
.box4b h2 {
  color: yellow !important;
  font-family: 'London Font', Consolas, "Courier New", "monospace";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  font-size: 0.8rem;
  line-height: 1.4em;
  letter-spacing: 0.07rem;
  text-align: center;
}
.box4b h1 {
  font-family: 'London Font', Consolas, "Courier New", "monospace";
  color: yellow;
  font-size: 1.3rem;
  font-weight: normal;
  font-style: normal;
  text-align: center;
}
.box4c p {
font-family: 'Helvetica Neue LT Std', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';	
font-style:normal;
font-weight: 400;;
  font-size: 0.75rem;
  text-align: center;
  line-height: 1.2em;
  max-width: 14.5rem!important;
  margin: 0 auto;
  padding:0 1rem;
}
.tier1:hover .box4c, .tier1:focus .box4c, .tier2:hover .box4c, .tier2:focus .box4c, .tier3:hover .box4c, .tier3:focus .box4c, .tier4:hover .box4c, .tier4:focus .box4c {
  display: block;
}
.mixtape {
  content: url("../../assets/prize_mixtapea.jpg");
  width: 100%;
  width: 50%;
  height: 50%;
  margin: 0 auto;
}
.box4c .dot {
  color: yellow;
  margin: -1rem 0 !important;
  line-height: 0 !important;
}
.hoodiefront {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: block;
  z-index: 101;
  position: relative;
  content: url("../../assets/prize_pack.png");
}
.vest {
  width: 60%;
  height: 60%;
  transform: rotate(6deg);
  z-index: 100;
  margin: -3rem auto 2rem auto;
  content: url("../../assets/prize_vest.png");
  position: relative;
}
/****pickup*****/
.box5 {
  background-color: yellow;
  border-bottom: 2px solid white;
  padding: 7rem 2rem;
}
.box5 h1 {
  color: black;
  font-family: 'London Font', Consolas, "Courier New", "monospace";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.5em;
  letter-spacing: 0.07rem;
  padding: 0 2rem;
}
.box5 p {
  color: black;
  font-family: 'Helvetica Neue LT Std', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';
  font-weight: 400;
  font-style: normal;
  font-size: 0.7rem;
  line-height: 1.5em;
  width: 100%;
  max-width: 28rem;
  margin: 2rem auto 1.5rem auto;
  padding: 0 2rem;
}
.box5 .bigbutton:hover, .box5 .bigbutton:focus {
  color: white !important;
  background-color: #d69417;
}
.box5 a:link {
  text-decoration: none !important;
}
.box5 .bigbutton {
  margin: 4rem auto 3rem auto !important;
  padding-top: 1rem;
  height: 2.7rem !important;
  max-width: 26rem !important;
}
.bigbutton {
  font-family: 'Helvetica Neue LT Std', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';
  background-color: #005da8;
  height: 2.5rem;
  width: 100%;
  clear: both;
  display: block;
  text-transform: uppercase;
  line-height: 1rem;
  font-size: 1.3rem;
  color: white;
  font-weight: 700;
  font-style: bold;
  text-align: center;
  max-width: 28rem;
  border-radius: 2rem;
  letter-spacing: 0 !important;
}
/****map menu*****/
.box3 {
  background-color: #282525;
  border-bottom: 2px solid white;
}
.box3 hr {
  border-top: 1px solid white;
}
.box3 h1, .box3 h2, .box3 h3, .box3 h4, .box3 h5, .box3 .h6, .box3 .h7, .firstbuy h1, .firstbuy h2, .firstbuy h3, .firstbuy h4, .firstbuy h5, .firstbuy.h6, .firstbuy .h7, #mint-cost2 {
  font-family: 'Helvetica Neue LT Std', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  margin: 0;
  padding: 0;
  display: block;
  letter-spacing: 0;
  font-weight: 700;
  font-style: normal;
}
.box3 h1, .firstbuy h1 {
  width: 4rem;
  height: 4rem;
  border-radius: 10rem;
  float: left;
  margin: 0 1rem 0 0;
  padding: 2rem 0 0 0;
  text-align: center;
  font-size: 2.7rem;
  line-height: 1rem;
  display: block;
}
.box3 h2, .firstbuy h2 {
  color: white;
  font-size: 2.7rem;
  line-height: 1.2em;
  margin: 0.95rem 0 0 0;
  padding: 0;
  float: left;
  width: calc(100% - 6rem);
}
.box3 h3, .firstbuy h3 {
  color: white;
  font-size: 1rem;
  line-height: 1rem;
  margin: 1rem 0 0 5.1rem;
  padding: 0;
  float: left;
  clear: both;
}
.box3 h4, .firstbuy h4 {
  color: white;
  font-size: 1rem;
  line-height: 1rem;
  margin: 0.4rem 0 0 5.1rem;
  padding: 0;
  float: left;
  clear: both;
  font-weight: bold !important;
}
.box3 .btn3 h4, .firstbuy h4 {
  color: #25b180;
  text-transform: uppercase;
}
.box3 .btn4 h4 {
  color: #bc00ff;
  text-transform: uppercase;
}
.box3 .btn5 h4 {
  color: #ff0049;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}
.box3 .btn3 h1, .box3 .btn3 .bigbutton, .firstbuy h1, .firstbuy .bigbutton {
  background-color: #25b180;
  color: white;
}
.box3 .btn4 h1, .box3 .btn4 .bigbutton {
  background-color: #bc00ff;
  color: white;
}
.box3 .btn5 h1, .box3 .btn5 .bigbutton {
  background-color: #ff0049;
  color: white;
}
#buy .h7, .firstbuy .h7 {
  padding-top: 0.9rem;
  height: auto;
  display: block;
  text-transform: uppercase;
  letter-spacing: 0;
  font-size: 0.7rem;
  line-height: 1em;
  color: #f5d59b !important;
  text-align: center;
  clear: both;
  width: 100%;
}
.box3 .bigbutton, .firstbuy .bigbutton {
  line-height: 1rem;
  margin: 1rem auto 0 auto !important;
  padding: 0.9rem 1rem 1.6rem 1rem;
  float: left;
  clear: both;
  height: 2rem;
  width: 100%;
  border-radius: 1000rem;
  text-align: center;
  font-size: 1.3rem;
  max-width: 28rem;
}
.box3 .btn3, .box3 .btn4, .box3 .btn5, .firstbuy {
  width: 100%;
  max-width: 28rem;
  margin: 0 auto 0 auto !important;
  padding: 4rem 1rem;
}

.firstbuy {padding:3rem 1rem!important;}
.box3 h5:hover, .box3 h5:focus, .box3 .bigbutton:hover, .box3 .bigbutton:focus, .firstbuy h5:hover, .firstbuy h5:focus, .firstbuy .bigbutton:hover, .firstbuy .bigbutton:focus {
  background-color: #d79417;
  color: white;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.3);
}
.box3 .h6, .firstbuy .h6, #mint-cost2 {
  clear: both;
  width: 100%;
  display: block;
  color: white;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 400;
  height: 2.5rem;
  border: 2px solid #f5d59b;
  border-radius: 10rem;
  line-height: 1rem;
  padding: 0.8rem 0rem 0 0rem;
  margin: 1rem auto -0.1rem auto;
}
.quantitytotal {
  padding-top: 0rem !important;
  display: block;
  height: auto;
  clear: both;
}
/***button is grey before wallet connects**/
.verify {
  background-color: rgba(82, 82, 82, 1.00) !important;
  color: black !important;
}
.beforeslider {
  margin-bottom: -3rem;
  height: 1px;
  width: 100%;
}


/***NEW SLIDER CSS***/


.slidecontainer {
  height: 7rem!important;
  margin: 0 auto 0 auto!important;
  z-index: 22!important;
  position: relative!important;
  display: block!important;
  clear: both!important;
  padding-top: 10rem!important;
  background-image: url("../../assets/buyme.gif")!important;
  background-repeat: no-repeat!important;
  background-position: bottom right!important;
  background-size: 8rem!important;
}


.MuiSlider-rail{
  height:1rem!important;
  margin-top:-2.8rem!important;
  background-image:linear-gradient(to left, rgba(0,0,0,0) 6rem,rgba(245,213,155,1.00))!important;
  opacity:1!important;
  border-radius:100rem!important;
}


.MuiSlider-root {
color:rgba(0,0,0,0)!important;
  height: 0rem!important;
  color: rgba(0, 0, 0, 0) !important;
  background-color: none!important;
  width: 100% !important;
  margin-left: 0.55rem!important;
  max-width: 24rem!important;
  margin-bottom: 0!important;
  padding:0!important;
}

.PrivateValueLabel-circle-4 {
  width: 3rem !important;
  height: 3rem !important;
  border-radius: 10rem!important;
  padding: 0!important;
  overflow: visible!important;
}



.jss5 {
  font-family: 'London Font', Consolas, "Courier New", "monospace"!important;
  font-weight: normal!important;
  font-style: normal!important;
  font-size:1rem!important; 
  border: 2px solid #f5d59b!important;
  border-radius:10rem!important;
  width:3rem!important;
  height:3rem!important;
  background-color:#d4922b!important;
  color:black!important;
  padding: 0.8rem 0.1rem 0 0;
}

.MuiSlider-valueLabel {
  font-family: 'London Font', Consolas, "Courier New", "monospace"!important;
  font-weight: normal!important;
  font-style: normal!important;
  font-size:1.5rem!important;
  border-radius:10rem!important;
  width:3rem!important;
  height:3rem!important;
  background-color:rgba(0,0,0,)!important;
  border:none; 
  text-align:center!important;
}

#slide-counter, .MuiSlider-markLabel {
  display: none !important;
  color:rgba(0,0,0,0)!important;
}


.MuiSlider-thumb {
  width: 0px!important;
  height:0px!important;
  color:rgba(0,0,0,0)!important;
  margin-top:-1rem!important;
}
.MuiSlider-mark,
.MuiSlider-markActive, 
.MuiSlider-thumb::after,
.MuiSlider-thumb::before,
.MuiSlider-track {
  width: 0px!important;
  height:0px!important;
  background-color:rgba(0,0,0,0)!important;
}


#buy, #login, #metaverse {
  padding-bottom: 6rem;
  display: block;
}
/****sponsors****/
.box6 {
  background-image: linear-gradient(180deg, #005da8, #e7ddd1);
  padding: 8rem 2rem;
}
.box6 h1, .box7 h1 {
  font-family: 'London Font', Consolas, "Courier New", "monospace";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  text-align: center;
  font-size: 4rem;
  line-height: 1em;
  margin: 0 auto 4rem auto;
  padding: 0;
  width: 100%;
  max-width: 20rem;
  color: yellow;
}
.sponsors {
  width: 100%;
  max-width: 20rem;
  height: auto;
  margin: 0 auto;
  padding: 2rem;
  display: block;
}
.sponsorscontainer {
  width: 100%;
  max-width: 50rem;
  margin: 0 auto;
}
.spon-1, .spon-2, .spon-3, .spon-4, .spon-5, .spon-6, .spon-7, .spon-8, .spon-9, .spon-10, .spon-11, .spon-12, .spon-13, .spon-14, .spon-15, .spon-16, .spon-17, .spon-18, .spon-19, .spon-20, .spon-21 {
  background-repeat: no-repeat;
  display: block;
  float: left;
  height: 8rem;
  background-position: center;
}
/**line1**/
.spon-1 {
  width: 50%;
  background-image: url("../../assets/spon1.png");
  background-size: 90%;
}
.spon-2 {
  width: 16%;
  background-image: url("../../assets/spon2.png");
  background-size: 90%;
}
.spon-3 {
  width: 16%;
  background-image: url("../../assets/spon3.png");
  background-size: 70%;
}
.spon-4 {
  width: 16%;
  background-image: url("../../assets/spon4.png");
  background-size: 100%;
}
/**line2**/
.spon-5 {
  width: 10%;
  background-image: url("../../assets/spon5.png");
  clear: left;
  background-size: 90%;
  margin-right: 4%;
}
.spon-6 {
  width: 11%;
  background-image: url("../../assets/spon6.png");
  background-size: 90%;
  margin-right: 4%;
}
.spon-7 {
  width: 10%;
  background-image: url("../../assets/spon7.png");
  background-size: 90%;
  margin-right: 4%;
}
.spon-8 {
  width: 16%;
  background-image: url("../../assets/spon8.png");
  background-size: 90%;
  margin-right: 3%;
}
.spon-9 {
  width: 20%;
  background-image: url("../../assets/spon9.png");
  background-size: 90%;
}
.spon-10 {
  width: 16%;
  background-image: url("../../assets/spon10.png");
  background-size: 90%;
  float: right !important;
}
/**line3**/
.spon-11 {
  width: 14%;
  background-image: url("../../assets/spon11.png");
  background-size: 90%;
}
.spon-12 {
  width: 18%;
  background-image: url("../../assets/spon12.png");
  background-size: 90%;
  margin-right: 4%;
}
.spon-13 {
  width: 8%;
  background-image: url("../../assets/spon13.png");
  background-size: 90%;
  margin-right: 4%;
}
.spon-14 {
  width: 27%;
  background-image: url("../../assets/spon14.png");
  background-size: 90%;
}
.spon-15 {
  width: 20%;
  background-image: url("../../assets/spon15.png");
  background-size: 90%;
  float: right !important;
}
/**line4**/
.spon-16 {
  width: 25%;
  background-image: url("../../assets/spon16.png");
  clear: left;
  background-size: 90%;
  margin-right: 7%;
}
.spon-17 {
  width: 25%;
  background-image: url("../../assets/spon17.png");
  background-size: 90%;
  margin-right: 3%;
}
.spon-18 {
  width: 20%;
  background-image: url("../../assets/spon18.png");
  background-size: 50%;
}
.spon-19 {
  width: 20%;
  background-image: url("../../assets/spon19.png");
  background-size: 90%;
  float: right !important;
}

/**line5**/
.spon-20 {
  width: 50%;
  background-image: url("../../assets/spon20.png");
  background-size: 70%;
}
.spon-21 {
  width: 50%;
  background-image: url("../../assets/spon21.png");
  background-size: 70%;
  float: right !important;
}
/****partners****/
.box7 {
  border-top: 2px solid white;
  background-image: linear-gradient(180deg, #f5d59b, #e7ddd1);
  padding: 6rem 2rem;
}
.logo2 {
  width: 100%;
  max-width: 20rem;
  content: url("../../assets/logo2.svg");
}
.box7 h2 {
  color: #005da8;
  font-family: 'London Font', Consolas, "Courier New", "monospace";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  width: 100%;
  max-width: 20rem;
  padding: 0;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  line-height: 1.4rem;
}
footer {
  background-color: #282525;
  border-top: 2px solid black;
  color: white;
  padding: 5rem 2rem 3rem 2rem;
  text-align: center;
}
footer h1 {
  font-family: 'London Font', Consolas, "Courier New", "monospace";
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  text-align: center;
  font-size: 3rem;
  line-height: 0.4rem;
  color: #d69417;
  margin: 0 auto;
  padding: 0;
}
.available {
  color: #f5d59b;
  font-size: 1rem !important;
  line-height: 1em;
  clear: both;
  display: block;
  margin-top: 2rem;
}
.socials {
  width: 100%;
  max-width: 16rem;
  height: 5rem;
  margin: -1rem auto 0 auto;
  clear: both;
  display: block;
}
.tw1 {
  float: left;
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 10rem;
  margin: 3rem 0.5rem 0 0.5rem;
  content: url("../../assets/ico-tw1.svg");
}
.tw2 {
  float: left;
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 10rem;
  margin: 3rem 0.5rem 0 0.5rem;
  content: url("../../assets/ico-tw2.svg");
}
.em {
  float: left;
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 10rem;
  margin: 3rem 0.5rem 0 0.5rem;
  content: url("../../assets/ico-email.svg");
}
.ig {
  float: left;
  display: block;
  width: 3rem;
  height: 3rem;
  border-radius: 10rem;
  margin: 3rem 0.5rem 0 0.5rem;
  content: url("../../assets/ico-ig.svg");
}
.tw1:hover, .tw1:focus, .tw2:hover, .tw2:focus, .em:hover, .em:focus, .ig:hover, .ig:focus {
  background-color: #005da8 !important;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 1);
}
footer p {
  font-family: 'Helvetica Neue LT Std', 'Helvetica Neue', Helvetica, Arial, 'sans-serif';
  font-weight: 400;
  font-style: normal;
  font-size: 0.6rem;
  line-height: 1.3em;
  margin-top: 2rem;
}

/********************** UNIVERSAL RESPONSIVE SIZES *********************/
/* all small screens */
@media only screen and (max-width: 600px) and (pointer: fine), only screen and (max-height: 600px) and (pointer: fine), only screen and (orientation: portrait) and (pointer: coarse), only screen and (orientation: landscape) and (pointer: coarse) {
  html, body {
    font-size: 16px;
    line-height: 16px;
  }
}
/* all med and large screens touch aka ipad */
@media only screen and (min-device-width: 992px) and (orientation: portrait) and (pointer: coarse), only screen and (min-device-height: 992px) and (orientation: landscape) and (pointer: coarse) {
  html, body {
    font-size: 24px;
    line-height: 24px;
  }
}
/* desktop */
@media only screen and (min-width: 600px) and (min-height:600px) and (pointer: fine) {
  html, body {
    font-size: 20px;
    line-height: 20px;
  }
}
/*********************START RESPONSIVE CODE*********************/
/* small portrait screens */
@media only screen and (orientation: portrait) and (pointer: coarse) {
  /****top nav*****/
 
  #mySidebar2 {
	padding: 9rem 2rem 6rem 2rem;
  }
  /****marquee and esc image*****/
  .noticemarquee {
    line-height: 1.1em;
  }
  /****landing*****/

.box1c {
	width: 100%;
	height: 10rem;
	top:1.4rem;
	left:1rem;
  max-height:none;
}
.firstbuy{
	max-width:calc(100vw - 2rem);
	margin:0 auto;

}
  /****about*****/
  .box2 h1 {
    font-size: 10vw;
  }
  .box2 p {
    font-size: 1rem;
    max-width: 100%
  }
  /****details*****/
  .box4 h1 {
    font-size: 10vw;
  }
  .guideimage {
	  width:100%;
	  background-size:150vw;
  }

  .box4 p {
    font-size: 1rem;
  }
 .box4c h1 {
	 font-size:2rem;
	 line-height:1em;
	 max-width:calc(50vw - 2rem);
	 margin-left:auto;
	 margin-right:auto;
 }

  /****pickup*****/
  .box5 h1 {
    font-size: 1.5rem;
  }
  .box5 p {
    font-size: 0.8rem;
  }
.box5 .bigbutton {
	max-width:calc(100vw - 4rem)!important;
}

  /****map menu*****/
.box3 .btn3, .box3 .btn4, .box3 .btn5 {
	max-width:calc(100vw - 2rem);
  }
  .box3 h1 {
    width: 4rem;
    height: 4rem;
    border-radius: 10rem;
    font-size: 2.7rem;
    line-height: 1rem;
  }
  .box3 h2 {
    font-size: 2.2rem;
    margin-top: 1.1rem;
  }

  .box3 h3 {
	line-height: 1.4em;
	padding-right:2.5rem;	
  }
  .box3 h4 {
	max-width:12rem;
    font-size: 1.1rem;
    line-height: 1.4em;
  }

 .box3 .h7 {
	 max-width:10rem;
	 margin: auto;


 }/***NEW SLIDER CSS***/

.MuiSlider-rail{
  margin-top:-3.2rem!important;
}


.MuiSlider-root {
  max-width: calc(100vw - 6rem)!important;
}


  /****sponsors***/
  .spon-1, .spon-2, .spon-3, .spon-4, .spon-5, .spon-6, .spon-7, .spon-8, .spon-9, .spon-10, .spon-11, .spon-12, .spon-13, .spon-14, .spon-15, .spon-16, .spon-17, .spon-18, .spon-19, .spon-20, .spon-21 {
    height: 7rem;
  }
  /**line1**/
  .spon-1 {
    width: 100%;
    background-size: 100%;
  }
  .spon-2 {
    width: 33%;
    background-size: 90%;
    background-position: left center;
  }
  .spon-3 {
    width: 33%;
    background-size: 70%;
    margin-right: 1%;
  }
  .spon-4 {
    width: 33%;
    background-size: 100%;
    background-position: right center;
  }
  /**line2**/
  .spon-5 {
    width: 33%;
    clear: left;
    background-size: 70%;
    margin-right: 1%;
    background-position: left center;
  }
  .spon-6 {
    width: 33%;
    background-size: 70%;
    margin-right: 0%;
  }
  .spon-7 {
    width: 33%;
    background-size: 73%;
    margin-right: 0%;
    float: right !important;
    background-position: right center;
  }
  .spon-8 {
    width: 23%;
    background-size: 100%;
    margin-right: 10%;
    clear: left;
  }
  .spon-9 {
    width: 33%;
    background-size: 100%;
  }
  .spon-10 {
    width: 30%;
    background-size: 90%;
    background-position: right;
    float: right !important;
  }
  /**line3**/
  .spon-11 {
    width: 30%;
    background-size: 100%;
    clear: left;
  }
  .spon-12 {
    width: 40%;
    background-size: 90%;
    margin-right: 4%;
  }
  .spon-13 {
    width: 20%;
    background-size: 90%;
    margin-right: 4%;
    float: right;
  }
  .spon-14 {
    clear: left;
    width: 48%;
    background-size: 90%;
    background-position: left;
  }
  .spon-15 {
    width: 48%;
    background-size: 90%;
    float: right !important;
    background-position: right;
  }
  /**line4**/
  .spon-16 {
    width: 48%;
    clear: left;
    background-size: 90%;
    margin-right: 0%;
  }
  .spon-17 {
    width: 48%;
    background-size: 90%;
    margin-right: 0%;
    float: right;
  }
  .spon-18 {
    clear: left;
    width: 48%;
    background-size: 50%;
  }
  .spon-19 {
    width: 48%;
    background-size: 90%;
    float: right !important;
  }

  /**line 5**/
  .spon-20 {
    width:100%;
    clear: right;
    background-size:100%;
  }

  .spon-21 {
    width:100%;
    clear: right;
    background-size:100%; 
  }
  /****partners****/
  .box7 img {
    max-width: 80%;
  }
}


/* all med portrait */
@media only screen and (min-device-width: 768px) and (orientation: portrait) and (pointer: coarse) {
  /****top nav*****/
  #mySidebar2 {
	padding: 4rem 2rem 5rem 2rem;
  }
  /****marquee and esc image*****/
  .noticemarquee {
    line-height: 1.25em;
  }
  /****landing*****/
.firstbuy {
	width:100%;
	max-width:26rem;
}

.box1c {
	max-width:23rem!important;
	height:20rem;
	left:2.1rem!important;
  top:-1rem;
  max-height:none;
}
  /****about*****/
  .box2 h1 {
    font-size: 4rem;
  }
  .box2 p {
    max-width: 30rem;
  }

  /****details*****/

.tier0 {
	width:100vw!important;
}
.tier1, .tier2 {
	box-shadow: none;
}
.box4 h1 {
	font-size: 4rem;
}

.guideimage {
	background-size:contain;
}

.box4 .arrow {
	margin:6rem 0 -1.5rem 70vw!important;
}

.box4c h1 {
	max-width:23vw;
}

.box4 p {
	max-width:35rem!important;
}
.box5 .bigbutton {
    max-width: 26rem!important;
}

/****menu map****/

.box3 .btn3, .box3 .btn4, .box3 .btn5 {
	max-width: 28rem;
  }
  
  .box3 h2 {
	font-size: 2.7rem;
    margin-top: 0.95rem;
  }

  .box3 h3 {
	line-height: 1rem;
	padding-right:0rem;	
  }
  .box3 h4 {
	max-width:100%;
	font-size: 1rem;
	line-height: 1rem;
  }

 .box3 .h7 {
	 max-width:100%;
	 margin: auto;
 }

 /***NEW SLIDER CSS***/

 .MuiSlider-rail{
  margin-top:-2.6rem!important;
}


.MuiSlider-root {
  max-width: 22rem!important;
}

  /****sponsors***/
  .sponsorscontainer {
    max-width: 90%;
  }
  .spon-1, .spon-2, .spon-3, .spon-4, .spon-5, .spon-6, .spon-7, .spon-8, .spon-9, .spon-10, .spon-11, .spon-12, .spon-13, .spon-14, .spon-15, .spon-16, .spon-17, .spon-18, .spon-19, .spon-20, .spon-21 {
    height: 8rem;
  }
  /**line1**/
  .spon-1 {
    width: 50%;
    background-size: 100%;
  }
  .spon-2 {
    width: 16%;
    background-size: 90%;
    background-position: center;
  }
  .spon-3 {
    width: 16%;
    background-size: 70%;
  }
  .spon-4 {
    width: 16%;
    background-size: 100%;
    background-position: center;
  }
  /**line2**/
  .spon-5 {
    width: 10%;
    clear: none;
    background-size: 90%;
    margin-right: 4%;
    background-position: center;
  }
  .spon-6 {
    width: 11%;
    background-size: 90%;
    margin-right: 4%;
  }
  .spon-7 {
    width: 10%;
    background-size: 90%;
    margin-right: 4%;
    float: left;
    background-position: center;
  }
  .spon-8 {
    width: 16%;
    background-size: 90%;
    margin-right: 3%;
    clear: none;
  }
  .spon-9 {
    width: 20%;
    background-size: 90%;
  }
  .spon-10 {
    width: 16%;
    background-size: 90%;
    background-position: center;
    float: right !important;
  }
  /**line3**/
  .spon-11 {
    width: 14%;
    background-size: 90%;
    clear: left;
  }
  .spon-12 {
    width: 18%;
    background-size: 90%;
    margin-right: 4%;
  }
  .spon-13 {
    width: 8%;
    background-size: 90%;
    margin-right: 0%;
    float: left;
  }
  .spon-14 {
    clear: none;
    width: 27%;
    background-size: 90%;
    background-position: center;
  }
  .spon-15 {
    width: 20%;
    background-size: 90%;
    float: right !important;
    background-position: center;
  }
  /**line4**/
  .spon-16 {
    width: 25%;
    clear: left;
    background-size: 90%;
    margin-right: 7%;
  }
  .spon-17 {
    width: 25%;
    background-size: 90%;
    margin-right: 3%;
    float: left;
  }
  .spon-18 {
    clear: none;
    width: 20%;
    background-size: 50%;
  }
  .spon-19 {
    width: 20%;
    background-size: 90%;
    float: right !important;
  }

  /**line 5**/
  .spon-20 {
    width:50%;
    clear: none;
    background-size:80%;
  }

  .spon-21 {
    width:50%;
    clear: right;
    background-size:80%; 
  }
}
/****small screens non touch up***/
@media only screen and (max-width: 600px) and (pointer: fine) {
  /****marquee and esc image*****/
  .noticemarquee {
    line-height: 1.1em;
  }
  /****landing*****/
  .box1c {
    max-width: 25rem;
    top:0;
    max-height:20rem;
  }
 
/***about***/
.box2 p { 
	font-size: 0.85rem;
	line-height: 1.5em;}

  /****details*****/
  .box4 h1 {
    font-size: 4rem;
  }
.box4 .arrow {
	margin: 6rem 0 -1.5rem 70vw;
}

.box4b h1 {
	font-size: 2rem;
	max-width:23vw;
	line-height:1em;
	margin:1rem auto;
}

.vest {
	margin-top:-5rem;
}


}
/*****small landscape touch****/
@media only screen and (max-height: 600px) and (pointer: fine), only screen and (orientation: landscape) and (pointer: coarse) {

  /****marquee and esc image*****/
  .noticemarquee {
    line-height: 1.1em;
  }
  /****landing*****/

  .box1c {
	width:10rem;
	height: 10rem;
	top:-0.5rem;
  left:1.5rem;
  max-height:none;
  }

  .firstbuy {
	  transform: scale(0.5);
  	left:-6rem;
	  bottom:-5rem;
  }

  /****about*****/
  .box2 h1 {
font-size:3rem;
max-width:25rem;
margin:0 auto 2rem auto;}


  .box2 p, .box4 p {
    font-size: 1rem;
	max-width:32rem!important;
  }

  .box4 h1 {
	font-size:3rem;
	max-width:25rem;
	margin:5rem auto 2rem auto;}
	.box4 p {
		max-width:34rem!important;
	}
	
  /****details*****/
 .box4b h1 {
	 font-size:1.5rem;
	 margin:1rem auto;
	 max-width:20vw;
	 line-height:1.5rem;
 }
  .box4b h2 {
	  font-size:1.4vw;
  }
  .box5 h1 {
    font-size: 1.5rem;
  }
  .box5 p {
    font-size: 0.8rem;
  }

  /*****slider***/


/***sponsors***/
  .sponsorscontainer {
	max-width: calc(100vw - 10rem);
  }
  .spon-1, .spon-2, .spon-3, .spon-4, .spon-5, .spon-6, .spon-7, .spon-8, .spon-9, .spon-10, .spon-11, .spon-12, .spon-13, .spon-14, .spon-15, .spon-16, .spon-17, .spon-18, .spon-19, .spon-20, .spon-21 {
	height: 6rem;}

}
/* ipad landscape */
@media only screen and (min-device-height: 768px) and (orientation: landscape) and (pointer: coarse) {


  /****landing*****/

  .box1c {
	width:25rem;
	height: 50vh;
	left:2.2rem;
	top:-1rem;
  }

  .firstbuy {
	  transform: scale(1);
	left:1rem;
	  bottom:1rem;
  }

  /****about*****/
  .box2 h1 {
	font-size: 4rem;
max-width:25rem;
margin:0 auto;}


  .box2 p, .box4 p {
	font-size: 1rem;
	max-width: 24rem;
  }

  .box4 h1 {
	font-size:3rem;
	max-width:25rem;
	margin:5rem auto 2rem auto;}
	.box4 p {
		max-width:34rem!important;
	}
	
  /****details*****/
 .box4b h1 {
	font-size: 2rem;
	 margin:2rem auto;
	 max-width: 11rem;
	 line-height:1.1em;
 }
  .box4b h2 {
	font-size: 0.8rem;
  }
  .box5 h1 {
	font-size: 0.8rem;
  }
  .box5 p {
    font-style: normal;
  font-size: 0.7rem;
  }


/***sponsors***/
  .sponsorscontainer {
	max-width: calc(100vw - 10rem);
  }
  .spon-1, .spon-2, .spon-3, .spon-4, .spon-5, .spon-6, .spon-7, .spon-8, .spon-9, .spon-10, .spon-11, .spon-12, .spon-13, .spon-14, .spon-15, .spon-16, .spon-17, .spon-18, .spon-19, .spon-20, .spon-21 {
	height: 9rem;}


 
}
/****small landscape not touch***/
@media only screen and (max-height: 600px) and (pointer: fine) {


  /****landing*****/
  .box1c {
    width:10rem;
    height: 10rem;
    left:1.5rem;
    top:-0.8rem;
    }
  .firstbuy {
bottom:-6rem;
  }


  
}



/********************** Extract *********************/
.w3-image{max-width:100%;height:auto}img{vertical-align:middle}a{color:inherit}
.w3-table,.w3-table-all{border-collapse:collapse;border-spacing:0;width:100%;display:table}.w3-table-all{border:1px solid #ccc}
.w3-bordered tr,.w3-table-all tr{border-bottom:1px solid #ddd}.w3-striped tbody tr:nth-child(even){background-color:#f1f1f1}
.w3-table-all tr:nth-child(odd){background-color:#fff}.w3-table-all tr:nth-child(even){background-color:#f1f1f1}
.w3-hoverable tbody tr:hover,.w3-ul.w3-hoverable li:hover{background-color:rgba(0,0,0,0)}.w3-centered tr th,.w3-centered tr td{text-align:center}
.w3-table td,.w3-table th,.w3-table-all td,.w3-table-all th{padding:8px 8px;display:table-cell;text-align:left;vertical-align:top}
.w3-table th:first-child,.w3-table td:first-child,.w3-table-all th:first-child,.w3-table-all td:first-child{padding-left:16px}
.w3-btn,.w3-button{border:none;display:inline-block;padding:8px 16px;vertical-align:middle;overflow:hidden;text-decoration:none;color:inherit;background-color:inherit;text-align:center;white-space:nowrap}
.w3-btn:hover{box-shadow:0 8px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19)}
.w3-btn,.w3-button{-webkit-touch-callout:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}   
.w3-disabled,.w3-btn:disabled,.w3-button:disabled{opacity:0.3}.w3-disabled *,:disabled *{pointer-events:none;}
.w3-btn.w3-disabled:hover,.w3-btn:disabled:hover{box-shadow:none}
.w3-badge,.w3-tag{background-color:#000;color:#fff;display:inline-block;padding-left:8px;padding-right:8px;text-align:center}.w3-badge{border-radius:50%}
.w3-ul{list-style-type:none;padding:0;margin:0}.w3-ul li{padding:8px 16px;border-bottom:1px solid #ddd}.w3-ul li:last-child{border-bottom:none}
.w3-tooltip,.w3-display-container{position:relative}.w3-tooltip .w3-text{display:none}.w3-tooltip:hover .w3-text{display:inline-block}
.w3-ripple:active{opacity:0.5}.w3-ripple{transition:opacity 0s}
.w3-input{padding:8px;display:block;border:none;border-bottom:1px solid #ccc;width:100%}
.w3-select{padding:9px 0;width:100%;border:none;border-bottom:1px solid #ccc}
.w3-dropdown-click,.w3-dropdown-hover{position:relative;display:inline-block;}
.w3-dropdown-hover:hover .w3-dropdown-content{display:block}
.w3-dropdown-hover:first-child,.w3-dropdown-click:hover{background-color:#CCCCCC;color:#000}
.w3-dropdown-hover:hover > .w3-button:first-child,.w3-dropdown-click:hover > .w3-button:first-child{background-color:#ccc;color:#000}
.w3-dropdown-content{color:#000;background-color:#fff;display:none;position:absolute;min-width:160px;margin:0;padding:0;z-index:1}
.w3-check,.w3-radio{width:24px;height:24px;position:relative;top:6px}
.w3-sidebar{height:100%;width:200px;background-color:rgba(0,0,0,0);position:fixed!important;z-index:1;overflow:auto}
.w3-bar-block .w3-dropdown-hover,.w3-bar-block .w3-dropdown-click{width:100%}
.w3-bar-block .w3-dropdown-hover .w3-dropdown-content,.w3-bar-block .w3-dropdown-click .w3-dropdown-content{min-width:100%}
.w3-bar-block .w3-dropdown-hover .w3-button,.w3-bar-block .w3-dropdown-click .w3-button{width:100%;text-align:left;padding:8px 16px}
.w3-main,#main{transition:margin-left .4s}
.w3-modal{z-index:3;display:none;padding-top:100px;position:fixed;left:0;top:0;width:100%;height:100%;overflow:auto;background-color:rgb(0,0,0);background-color:rgba(0,0,0,0.4)}
.w3-modal-content{margin:auto;background-color:#fff;position:relative;padding:0;outline:0;width:600px}
.w3-bar{width:100%;overflow:hidden}.w3-center .w3-bar{display:inline-block;width:auto}
.w3-bar .w3-bar-item{padding:8px 16px;float:left;width:auto;border:none;display:block;outline:0}
.w3-bar .w3-dropdown-hover,.w3-bar .w3-dropdown-click{position:static;float:left}
.w3-bar .w3-button{white-space:normal}
.w3-bar-block .w3-bar-item{width:100%;display:block;padding:8px 16px;text-align:left;border:none;white-space:normal;float:none;outline:0}
.w3-bar-block.w3-center .w3-bar-item{text-align:center}.w3-block{display:block;width:100%}
.w3-responsive{display:block;overflow-x:auto}
.w3-container:after,.w3-container:before,.w3-panel:after,.w3-panel:before,.w3-row:after,.w3-row:before,.w3-row-padding:after,.w3-row-padding:before,
.w3-cell-row:before,.w3-cell-row:after,.w3-clear:after,.w3-clear:before,.w3-bar:before,.w3-bar:after{content:"";display:table;clear:both}
.w3-col,.w3-half,.w3-third,.w3-twothird,.w3-threequarter,.w3-quarter{float:left;width:100%}
.w3-col.s1{width:8.33333%}.w3-col.s2{width:16.66666%}.w3-col.s3{width:24.99999%}.w3-col.s4{width:33.33333%}
.w3-col.s5{width:41.66666%}.w3-col.s6{width:49.99999%}.w3-col.s7{width:58.33333%}.w3-col.s8{width:66.66666%}
.w3-col.s9{width:74.99999%}.w3-col.s10{width:83.33333%}.w3-col.s11{width:91.66666%}.w3-col.s12{width:99.99999%}
@media (min-width:601px){.w3-col.m1{width:8.33333%}.w3-col.m2{width:16.66666%}.w3-col.m3,.w3-quarter{width:24.99999%}.w3-col.m4,.w3-third{width:33.33333%}
.w3-col.m5{width:41.66666%}.w3-col.m6,.w3-half{width:49.99999%}.w3-col.m7{width:58.33333%}.w3-col.m8,.w3-twothird{width:66.66666%}
.w3-col.m9,.w3-threequarter{width:74.99999%}.w3-col.m10{width:83.33333%}.w3-col.m11{width:91.66666%}.w3-col.m12{width:99.99999%}}
@media (min-width:993px){.w3-col.l1{width:8.33333%}.w3-col.l2{width:16.66666%}.w3-col.l3{width:24.99999%}.w3-col.l4{width:33.33333%}
.w3-col.l5{width:41.66666%}.w3-col.l6{width:49.99999%}.w3-col.l7{width:58.33333%}.w3-col.l8{width:66.66666%}
.w3-col.l9{width:74.99999%}.w3-col.l10{width:83.33333%}.w3-col.l11{width:91.66666%}.w3-col.l12{width:99.99999%}}
.w3-rest{overflow:hidden}.w3-stretch{margin-left:-16px;margin-right:-16px}
.w3-content,.w3-auto{margin-left:auto;margin-right:auto}.w3-content{max-width:980px}.w3-auto{max-width:1140px}
.w3-cell-row{display:table;width:100%}.w3-cell{display:table-cell}
.w3-cell-top{vertical-align:top}.w3-cell-middle{vertical-align:middle}.w3-cell-bottom{vertical-align:bottom}
.w3-hide{display:none!important}.w3-show-block,.w3-show{display:block!important}.w3-show-inline-block{display:inline-block!important}
@media (max-width:1205px){.w3-auto{max-width:95%}}
@media (max-width:600px){.w3-modal-content{margin:0 10px;width:auto!important}.w3-modal{padding-top:30px}
.w3-dropdown-hover.w3-mobile .w3-dropdown-content,.w3-dropdown-click.w3-mobile .w3-dropdown-content{position:relative}	
.w3-hide-small{display:none!important}.w3-mobile{display:block;width:100%!important}.w3-bar-item.w3-mobile,.w3-dropdown-hover.w3-mobile,.w3-dropdown-click.w3-mobile{text-align:center}
.w3-dropdown-hover.w3-mobile,.w3-dropdown-hover.w3-mobile .w3-btn,.w3-dropdown-hover.w3-mobile .w3-button,.w3-dropdown-click.w3-mobile,.w3-dropdown-click.w3-mobile .w3-btn,.w3-dropdown-click.w3-mobile .w3-button{width:100%}}
@media (max-width:768px){.w3-modal-content{width:500px}.w3-modal{padding-top:50px}}
@media (min-width:993px){.w3-modal-content{width:900px}.w3-hide-large{display:none!important}.w3-sidebar.w3-collapse{display:block!important}}
@media (max-width:992px) and (min-width:601px){.w3-hide-medium{display:none!important}}
@media (max-width:992px){.w3-sidebar.w3-collapse{display:none}.w3-main{margin-left:0!important;margin-right:0!important}.w3-auto{max-width:100%}}
.w3-top,.w3-bottom{position:fixed;width:100%;z-index:1}.w3-top{top:0}.w3-bottom{bottom:0}
.w3-overlay{position:fixed;display:none;width:100%;height:100%;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.5);z-index:2}
.w3-display-topleft{position:absolute;left:0;top:0}.w3-display-topright{position:absolute;right:0;top:0}
.w3-display-bottomleft{position:absolute;left:0;bottom:0}.w3-display-bottomright{position:absolute;right:0;bottom:0}
.w3-display-middle{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%)}
.w3-display-left{position:absolute;top:50%;left:0%;transform:translate(0%,-50%);-ms-transform:translate(-0%,-50%)}
.w3-display-right{position:absolute;top:50%;right:0%;transform:translate(0%,-50%);-ms-transform:translate(0%,-50%)}
.w3-display-topmiddle{position:absolute;left:50%;top:0;transform:translate(-50%,0%);-ms-transform:translate(-50%,0%)}
.w3-display-bottommiddle{position:absolute;left:50%;bottom:0;transform:translate(-50%,0%);-ms-transform:translate(-50%,0%)}
.w3-display-container:hover .w3-display-hover{display:block}.w3-display-container:hover span.w3-display-hover{display:inline-block}.w3-display-hover{display:none}
.w3-display-position{position:absolute}
.w3-circle{border-radius:50%}
.w3-round-small{border-radius:2px}.w3-round,.w3-round-medium{border-radius:4px}.w3-round-large{border-radius:8px}.w3-round-xlarge{border-radius:16px}.w3-round-xxlarge{border-radius:32px}
.w3-row-padding,.w3-row-padding>.w3-half,.w3-row-padding>.w3-third,.w3-row-padding>.w3-twothird,.w3-row-padding>.w3-threequarter,.w3-row-padding>.w3-quarter,.w3-row-padding>.w3-col{padding:0 8px}
.w3-container,.w3-panel{padding:0.01em 16px}.w3-panel{margin-top:16px;margin-bottom:16px}
.w3-code,.w3-codespan{font-family:Consolas,"courier new";font-size:16px}
.w3-code{width:auto;background-color:#fff;padding:8px 12px;border-left:4px solid #4CAF50;word-wrap:break-word}
.w3-codespan{color:crimson;background-color:#f1f1f1;padding-left:4px;padding-right:4px;font-size:110%}
.w3-card,.w3-card-2{box-shadow:0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12)}
.w3-card-4,.w3-hover-shadow:hover{box-shadow:0 4px 10px 0 rgba(0,0,0,0.2),0 4px 20px 0 rgba(0,0,0,0.19)}
.w3-spin{animation:w3-spin 2s infinite linear}@keyframes w3-spin{0%{transform:rotate(0deg)}100%{transform:rotate(359deg)}}
.w3-animate-fading{animation:fading 10s infinite}@keyframes fading{0%{opacity:0}50%{opacity:1}100%{opacity:0}}
.w3-animate-opacity{animation:opac 0.8s}@keyframes opac{from{opacity:0} to{opacity:1}}
.w3-animate-top{position:relative;animation:animatetop 0.4s}@keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}
.w3-animate-left{position:relative;animation:animateleft 0.4s}@keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}
.w3-animate-right{position:relative;animation:animateright 0.4s}@keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}
.w3-animate-bottom{position:relative;animation:animatebottom 0.4s}@keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}
.w3-animate-zoom {animation:animatezoom 0.6s}@keyframes animatezoom{from{transform:scale(0)} to{transform:scale(1)}}
.w3-animate-input{transition:width 0.4s ease-in-out}.w3-animate-input:focus{width:100%!important}
.w3-opacity,.w3-hover-opacity:hover{opacity:0.60}.w3-opacity-off,.w3-hover-opacity-off:hover{opacity:1}
.w3-opacity-max{opacity:0.25}.w3-opacity-min{opacity:0.75}
.w3-greyscale-max,.w3-grayscale-max,.w3-hover-greyscale:hover,.w3-hover-grayscale:hover{filter:grayscale(100%)}
.w3-greyscale,.w3-grayscale{filter:grayscale(75%)}.w3-greyscale-min,.w3-grayscale-min{filter:grayscale(50%)}
.w3-sepia{filter:sepia(75%)}.w3-sepia-max,.w3-hover-sepia:hover{filter:sepia(100%)}.w3-sepia-min{filter:sepia(50%)}
.w3-tiny{font-size:10px!important}.w3-small{font-size:12px!important}.w3-medium{font-size:15px!important}.w3-large{font-size:18px!important}
.w3-xlarge{font-size:24px!important}.w3-xxlarge{font-size:36px!important}.w3-xxxlarge{font-size:48px!important}.w3-jumbo{font-size:64px!important}
.w3-left-align{text-align:left!important}.w3-right-align{text-align:right!important}.w3-justify{text-align:justify!important}.w3-center{text-align:center!important}
.w3-border-0{border:0!important}.w3-border{border:1px solid #ccc!important}
.w3-border-top{border-top:1px solid #ccc!important}.w3-border-bottom{border-bottom:1px solid #ccc!important}
.w3-border-left{border-left:1px solid #ccc!important}.w3-border-right{border-right:1px solid #ccc!important}
.w3-topbar{border-top:6px solid #ccc!important}.w3-bottombar{border-bottom:6px solid #ccc!important}
.w3-leftbar{border-left:6px solid #ccc!important}.w3-rightbar{border-right:6px solid #ccc!important}
.w3-section,.w3-code{margin-top:16px!important;margin-bottom:16px!important}
.w3-margin{margin:16px!important}.w3-margin-top{margin-top:16px!important}.w3-margin-bottom{margin-bottom:16px!important}
.w3-margin-left{margin-left:16px!important}.w3-margin-right{margin-right:16px!important}
.w3-padding-small{padding:4px 8px!important}.w3-padding{padding:8px 16px!important}.w3-padding-large{padding:12px 24px!important}
.w3-padding-16{padding-top:16px!important;padding-bottom:16px!important}.w3-padding-24{padding-top:24px!important;padding-bottom:24px!important}
.w3-padding-32{padding-top:32px!important;padding-bottom:32px!important}.w3-padding-48{padding-top:48px!important;padding-bottom:48px!important}
.w3-padding-64{padding-top:64px!important;padding-bottom:64px!important}
.w3-padding-top-64{padding-top:64px!important}.w3-padding-top-48{padding-top:48px!important}
.w3-padding-top-32{padding-top:32px!important}.w3-padding-top-24{padding-top:24px!important}
.w3-left{float:left!important}.w3-right{float:right!important}
.w3-transparent,.w3-hover-none:hover{background-color:transparent!important}
.w3-hover-none:hover{box-shadow:none!important}